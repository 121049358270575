@import "@/scss/_variables.scss";































































.tabs-container {
  .tabs-header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-bottom: 14px;
    margin-bottom: 32px;

    font-weight: bold;
    font-size: 0.762rem;
    list-style: none;
    border-bottom: 1px solid #CAD3D9;

    @media (max-width: $width-tablet) {
      flex-direction: column;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
      color: #69777F;

      @media (max-width: $width-tablet) {
        padding-left: 10px;
        font-size: 1rem;
      }

      &.selected {
        position: relative;

        color: #E3000B;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -14px;
          width: 100%;
          height: 4px;
          background-color: #E3000B;
        }

        @media (max-width: $width-tablet) {
          &:after {
            width: 4px;
            height: 100%;
            top: 0;
            left: 0;
            bottom: initial;
          }
        }
      }

      .marker + span {
        margin-left: 8px;
      }

      button {
        margin-left: 8px;
        padding: 0;

        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}
