@media print {
  .pane-detection-main > .grid {
    grid-column-gap: 50px !important;
  }

  .page-header + main {
    padding-top: 0 !important;
  }

  .page-header {
    display: none;
  }

  .tabs-container {
    .tabs-header {
      display: none;
    }

    .tabs-item {
      display: block !important;
    }
  }

  .output-result-list {
    display: none !important;
  }

  .input-base-item-inner-content {
    display: block !important;
  }

  .input-base-button-wrapper {
    display: none !important;
  }
}