@import "@/scss/_variables.scss";







































































.pane-detection-main {
  .result-container {
    .result-image {
      width: 100%;
      height: auto;
    }

    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      border: 1px solid #E3000B;
      border-radius: 50%;
      cursor: pointer;
      color: #E3000B;
      background-color: #FFFFFF;
      font-weight: bold;
      font-size: 0.762rem;

      &:hover {
        color: #FFFFFF;
        background-color: #E3000B;
      }

      &.selected {
        color: #FFFFFF;
        background-color: #E3000B;
      }
    }

    .spacing {
      margin-bottom: 48px;
    }

    .result-wrapper {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr 1fr;
      width: 100%;
      margin-bottom: 48px;

      font-size: 0.762rem;
      color: #293136;

      tbody, tr {
        display: contents;
      }

      tr {
        height: auto;

        &.border-bottom {
          td {
            border-bottom: 1px solid #69777F;
          }
        }

        td {
          width: 100%;
          height: 100%;
          padding: 8px 0 8px 0;
        }
      }

      tbody {
        font-family: futura-pt-book, sans-serif;

        sub {
          vertical-align: sub;
          font-size: 0.5rem;
        }
      }
    }

    .output-result-list {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:before {
        content: '';
        position: absolute;
        left: -32px;
        width: calc(100% + 64px);
        height: 1px;

        background-color: #CAD3D9;
      }

      .output-result-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: futura-pt-condensed, sans-serif;

        &:first-child {
          padding: 16px 32px 16px 0px;
        }

        &:last-child {
          padding: 16px 0px 16px 32px;

          border-right: none;
        }

        font-size: 0.762rem;
        color: #293136;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;

        border-right: 1px solid #CAD3D9;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
