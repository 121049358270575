@import "@/scss/_variables.scss";












































































































.input-base-item {
  width: 100%;
  margin-bottom: 8px;

  &.active {
    margin-bottom: 36px;

    .input-base-item-inner {
      position: relative;
      padding: 10px 32px 24px 32px;

      border: 1px solid #E3000B;

      @media (max-width: $width-tablet) {
        padding: 10px 16px 24px 16px;
      }

      .input-base-item-inner-header {
        color: #E3000B;

        span {
          color: #293136;
        }
      }

      .input-base-item-inner-content {
        margin-top: 24px;
      }
    }
  }

  &.complete {
    &:not(:last-child) {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 1px;
        height: 16px;
        margin: auto;

        background-color: #CAD3D9;
      }
    }
    margin-bottom: 16px;

    .input-base-item-inner {
      padding: 10px 32px 24px 32px;

      @media (max-width: $width-tablet) {
        padding: 10px 16px 24px 16px;
      }

      .input-base-item-inner-content {
        margin-top: 24px;
        max-height: 100%;
      }
    }
  }

  &-inner {
    display: block;
    width: 100%;
    padding: 10px 32px;

    background-color: #FFFFFF;
    border: 1px solid #CAD3D9;
    text-align: left;

    &-header {
      display: flex;
      justify-content: space-between;

      font-size: 1rem;
      font-family: futura-pt-condensed, sans-serif;
      font-weight: bold;
      color: #CAD3D9;

      @media (max-width: $width-tablet) {
        font-size: 1.313rem;
      }

      span {
        color: #69777F;
      }
    }

    &-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    &-help {
      padding: 0;

      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.input-base-button-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -32px;
    width: 1px;
    height: 32px;
    left: 0;
    right: 0;
    margin: 0 auto;

    background-color: #E3000B;
  }

  .input-base-button-inner {
    display: inline-block;
    width: auto;
    padding: 4px;

    border: 1px solid #E3000B;

    .input-base-button {
      padding: 8px 16px;

      background-color: #E3000B;
      border: none;
      color: #FFFFFF;

      &.last {
        background-color: transparent;
        color: #E3000B;
      }

      font-family: futura-pt-condensed, sans-serif;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
