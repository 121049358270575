@import "@/scss/_variables.scss";

















.imprint {
  font-family: futura-pt, sans-serif;
  font-size: 0.571rem;
  color: #293136;

  @media (max-width: $width-tablet) {
    font-size: 0.75rem;
    line-height: 0.9rem;
  }

  .underline {
    text-decoration: underline;
  }

  > * {
    line-height: 0.8rem;
    margin-bottom: 0;
  }

  &-name {
    font-weight: bold;
  }

  &-contact-information {
    margin-bottom: 16px;
  }
}
