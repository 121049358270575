@import "@/scss/_variables.scss";











































@import "src/scss/paneDetectionPrint";

.pane-detection-main {
  >.grid {
    grid-column-gap: 160px;
  }

  .page-title {
    margin: 15px 0 32px 0;

    font-size: 1.75rem;
    line-height: 2.2rem;
    color: #E3000B;
  }

  .hide-result {
    grid-template-columns: 1fr;
  }

  .input-manager {
    margin-bottom: 64px;
  }

  .result {
    width: 100%;
    padding: 32px 32px 0 32px;
    margin-bottom: 64px;

    background-color: #FFFFFF;
    border: 1px solid #CAD3D9;

    @media (max-width: $width-tablet) {
      width: calc(100% + 16px * 2);
      padding: 32px 16px 0 16px;
      margin-left: -16px;
      overflow: hidden;
    }
  }
}
